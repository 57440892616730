<template>
  <OnboardingStep
    :title="$t('onboarding.nightlyReportsSetupView.title')"
    :next-text="$t('onboarding.nightlyReportsSetupView.nextText')"
    v-bind="$props"
  >
    <NightlyReportsView v-bind="$props" />
  </OnboardingStep>
</template>

<script>
import { BaseStepView } from "./BaseStepView";
import NightlyReportsView from "../Settings/AutomationView/NightlyReportsView";

export default {
  name: "NightlyReportsSetupView",
  components: { NightlyReportsView },
  extends: BaseStepView,
};
</script>
